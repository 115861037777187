import { Controller } from 'stimulus'
import axios from 'axios'
require('fslightbox')

export default class extends Controller {
  static get targets() {
    return [ 'entries', 'page']
  }

  initialize() {
    let options = {
      rootMargin: '200px',
    }

    this.intersectionObserver = new IntersectionObserver(entries => {
      this.processIntersectionEntries(entries), options
    })
  }

  connect() {
    this.intersectionObserver.observe(this.pageTarget)
    refreshFsLightbox()
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.pageTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.pageTarget.dataset.nextPage
    if (next_page === 'null') { return }

    axios.get(`${window.location.origin}/gallery.json?page=${next_page}`)
      .then(response => {
        this.entriesTarget.insertAdjacentHTML('beforeend', response.data.entries)
        refreshFsLightbox()
        this.pageTarget.dataset.nextPage = response.data.next_page
      })
  }
}
