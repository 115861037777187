import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [ 'query', 'filterable' ]
  }

  filter (event) {
    let lowerCaseFilterTerm = this.queryTarget.value.toLowerCase()

    this.filterableTargets.forEach((el, i) => {
      let filterableKey =  el.getAttribute('data-filter-key')

      el.classList.toggle('hidden', !filterableKey.includes( lowerCaseFilterTerm ) )
    })
  }
}
