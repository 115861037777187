<template>
  <div>
    <CountrySelect :selectedCountry="selectedCountry" />
    <LanguageSelect :selectedLanguage="selectedLanguage" />
    <CurrencySelect :selectedCurrency="selectedCurrency" />
  </div>
</template>

<script>
import CountrySelect from './global_settings/CountrySelect.vue'
import LanguageSelect from './global_settings/LanguageSelect.vue'
import CurrencySelect from './global_settings/CurrencySelect.vue'

export default {
  props: {
    selectedCountry: Object,
    selectedCurrency: Object,
    selectedLanguage: Object
  },
  components: {
    CountrySelect,
    LanguageSelect,
    CurrencySelect
  },
  data: function () {
    return {
    }
  }
}
</script>
