<template>
  <div>
    <div class="appbar-list__global-dropdown__item" @click="openModal">
      <div class="title">Currency</div>
      <div class="value value--currency">{{ (selected || {}).code }}</div>
    </div>

    <div class="currency-search">
      <modal name="currency-modal" height="auto" :adaptive="true"
        @opened="openMultiselect"
      >
        <multiselect
          ref="multiselect"
          :value="selected"
          @select="onSelected"
          placeholder="Search currency"
          :options="currencies"
          :custom-label="getOptionLabel"
          :internalSearch="true"
          track-by="id"
        >
          <span slot="noResult">Nothing was found</span>
        </multiselect>
        <div></div>
      </modal>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  props: {
    selectedCurrency: Object
  },
  components: {
    Multiselect
  },
  data: function () {
    return {
      selected: this.selectedCurrency,
      currencies: []
    }
  },
  methods: {
    getOptionLabel (option) {
      return `${option.name} - ${option.code}`
    },
    onSelected (option) {
      this.selected = option
      this.$axios({url: `/global_settings/select_currency?id=${option.id}`, baseURL: '', method: 'post'})
        .then(response => {
          localStorage.setItem('location_currency', option.code)
          window.dispatchEvent( new Event('storage') )
          this.$modal.hide('currency-modal')
          if (!window.location.href.includes("discover")) {
            location.reload()
          }
        })
    },
    openModal () {
      if (this.currencies.length > 0) {
        this.$modal.show('currency-modal')
      } else {
        this.$axios({url: '/global_settings/currencies', baseURL: '', method: 'get'})
        .then(response => {
          this.currencies = response.data
          this.$modal.show('currency-modal')
        })
      }
    },
    openMultiselect () {
      this.$refs.multiselect.activate();
    },
    closeMultiselect () {
      this.$refs.multiselect.deactivate();
    }
  }
}
</script>
